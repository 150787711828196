import { NumericFlag, ResponseObject, UserBillingAddress, UserDataObject } from "types";
import apiService from "services/ApiService";

type LoginData = {
	email: string
	password: string
	reCaptchaToken: string
}
type LoginVerifyData = {
	email: string
	code: string
}


export const apiLogin = async (data: LoginData): Promise<ResponseObject<UserDataObject>> => {
	const response = await apiService.auth('/login', data);

	if (response.success) {
		if (response.data && response.data.hasOwnProperty('required_authentication')) {

		} else {
			localStorage.setItem('@userData', JSON.stringify({
				...response.data,
				roleName: response.data.role.name,
			}));
	
			const token = response.data.token;
			apiService.setToken(token);
		}
	}

	return response;
}

export const apiLoginVerify = async (data: LoginVerifyData): Promise<ResponseObject<UserDataObject>> => {
	const response = await apiService.auth('/login-verify', data);

	if (response.success) {
		localStorage.setItem('@userData', JSON.stringify({
			...response.data,
			roleName: response.data.role.name,
		}));

		const token = response.data.token;
		apiService.setToken(token);
	}

	return response;
}


export const apiLogout = async (): Promise<ResponseObject<null>> => {
	const response = await apiService.post('/logout', {});

	return response;
}

