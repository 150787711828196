import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import interfaceMangerSlice from './interfaceManger';
import eventSlice from './eventSlice';

export const store = configureStore({
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	reducer: {
		auth: authSlice,
		interfaceManger: interfaceMangerSlice,
		events: eventSlice,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
