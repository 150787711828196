import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { AdminUsers, Event } from 'types';
import { apiUpdateEvent } from 'api/events';
import dayjs from 'dayjs';
import { batch } from 'react-redux';
import { TimeZones } from 'constant';
import { unsetEditEvent } from 'redux/eventSlice';
import { InputGroup } from 'react-bootstrap';
import { apiUpdateUser } from 'api/users';

type UserUpdateProps = {
	data: AdminUsers;
	onCancel: () => void
}

const UserUpdate = ({ data, onCancel }: UserUpdateProps) => {
	const [userData, setUserData] = useState<any>({
		// name: data.name,
		// email: data.email,
		verified: data.verified,
		event_credits: (data.event_credits) ? data.event_credits : 0,
		is_blocked: data.is_blocked,
	});



	const handleUpdate = async () => {
		console.log({
			userData,
		})

		const response = await apiUpdateUser(data.id, {
			...userData,
		})

		if (response.success) {
			onCancel()
		} else {
			alert(response.message)
		}
	}

	return (
		<>
			<Modal show={true} className="createNewAccountModal" onHide={onCancel} aria-labelledby="contained-modal-title-vcenter" >
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							<span>Edit User - {data.id}</span>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className={`step-form1`}>
							<Form.Group className="mb-3">
								<Form.Label>Name</Form.Label>
								<Form.Control type="text" placeholder="" disabled value={data.name} />
							</Form.Group>

							<Form.Group className="mb-4">
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" placeholder="" disabled value={data.email} />
							</Form.Group>
							
							<Form.Group className="mb-3" id="blockCheckbox">
								<Form.Check id="blockCheckbox1" type="checkbox" name="block" 
									label="Block" 
									checked={userData.is_blocked === 1} 
									onChange={e => setUserData({ ...userData, is_blocked: ((userData.is_blocked === 1) ? 0 : 1) })} 
								/>
							</Form.Group>

							<Form.Group className="mb-3" id="verifiedCheckbox">
								<Form.Check id="verifiedCheckbox1" type="checkbox" name="verified" 
									label="Active" 
									disabled={(data.verified === 1)}
									checked={userData.verified === 1}
									onChange={e => setUserData({ ...userData, verified: ((userData.verified === 1) ? 0 : 1) })} 
								/>
							</Form.Group>

							<Form.Group className="mb-4">
								<Form.Label>Event Credits</Form.Label>
								<Form.Control type="number" placeholder="" value={userData?.event_credits} onChange={e => setUserData({ ...userData, event_credits: e.target.value })} />
							</Form.Group>
						</div>

						<div className={`actionBtns`}>
							<Button variant="secondary" className={`btn-cancel `} onClick={onCancel}>
								<span className={`step1`}>Cancel</span>
							</Button>

							<Button variant="primary" className={`btn-steps `} onClick={handleUpdate}>
								<span className={`step1 d-flex align-items-center justify-content-between`}>
									<span className="label">Update User</span>
									<span className="arrow">
										<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</span>
								</span>
							</Button>
						</div>
					</Modal.Body>
				</>
			</Modal>
		</>
	)
}


export default UserUpdate;