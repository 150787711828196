import React, { Routes, Route } from 'react-router-dom';

import AdminLayout from 'layout/AdminLayout';

import Dashboard from 'views/Admin/Dashboard';
import Users from 'views/Admin/Users';

import NotFound from 'views/NotFound';

const AdminRoutes = (): JSX.Element => {

	return (
		<Routes>
			<Route path="/" element={<AdminLayout />}>
				<Route element={<Dashboard />} path="/" />
				<Route element={<Dashboard />} path="/dashboard" />
				<Route element={<Users />} path="/users" />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	)
}

export default AdminRoutes;