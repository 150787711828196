import { ResponseObject, Event, EventDataField, EventStatusType, PaginationMetaData } from "types";
import apiService from "services/ApiService";
import cacheService from "services/CacheService";

export type ApiEventsResponse = {
	items: Event[]
	meta_data: PaginationMetaData
}

type QueryParams = {
	page: number,
	limit: number,
	orderBy: string,
	orderType: string,
	q?: string
}

export const apiGetEvent = async (
	status: EventStatusType[],
	{
		page = 1,
		limit = 10,
		orderBy = 'id',
		orderType = 'DESC',
		q
	}: QueryParams
): Promise<ResponseObject<ApiEventsResponse>> => {

	if (!cacheService.checkIsOnline()) {
		return cacheService.makeOfflineResponse(`@events`);
	}

	const response = await apiService.get('/events', {
		status: status ? status.join(',') : null,
		page,
		limit,
		orderBy,
		orderType,
		q
	});

	localStorage.setItem(`@events`, JSON.stringify(response.data));

	return response;
}


export const apiUpdateEvent = async (eventId: string | number, data: any): Promise<ResponseObject<Event>> => {
	return await apiService.postMultipart(`/update-event/${eventId}`, data);
}

export const apiUpdateEventStatus = async (eventId: string | number, status: EventStatusType): Promise<ResponseObject<Event>> => {
	return await apiService.postMultipart(`/update-event-status/${eventId}`, {
		status
	});
}

export const apiDeleteEvent = async (eventId: string | number): Promise<ResponseObject<null>> => {
	return await apiService.delete(`/delete-event/${eventId}`);
}
