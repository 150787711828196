
import { BASE_URL } from "constant/index";
import { Guest, ResponseObject } from "types";

interface CacheApi {
	(
		method: string, 
		url: string, 
		data: any,
	): void
}

class CacheService {

	API_ERROR: ResponseObject<null> = {
		success: false,
		data: null,
		message: 'Something went wrong!',
	}

	constructor() {
		console.log('ApiService', BASE_URL)
	}


	checkIsOnline(): boolean {
		return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
			? navigator.onLine
			: true;
	}

	makeOfflineResponse(key: string): ResponseObject<any> {
		try {
			let response = localStorage.getItem(key);
			if (response) {
				return {
					success: true,
					data: JSON.parse(response),
					message: 'success',
				};
			} else {
				return {...this.API_ERROR};
			}
		} catch (e) {
			return {...this.API_ERROR};
		}
	}

	getEventGuests(key: string): Guest[] {
		try {
			let response = localStorage.getItem(key);
			if (response) {
				return JSON.parse(response);
			} else {
				return [];
			}
		} catch (e) {
			return [];
		}
	}

	searchGuest(items: Guest[], q: string = ''): Guest[] {
		const search = q.toLowerCase().split(' ');
		let searchedItems: Guest[] = [];

		for (let guest of items) {
			for (const word of search) {
				if (guest.first_name && guest.first_name.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.last_name && guest.last_name.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.company && guest.company.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.phone && guest.phone.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.alt_phone && guest.alt_phone.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.email && guest.email.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.unique_code && guest.unique_code.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.notification && guest.notification.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_1 && guest.other_field_1.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_2 && guest.other_field_2.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_3 && guest.other_field_3.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_4 && guest.other_field_4.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_5 && guest.other_field_5.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_6 && guest.other_field_6.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_7 && guest.other_field_7.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_8 && guest.other_field_8.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_9 && guest.other_field_9.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_10 && guest.other_field_10.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_11 && guest.other_field_11.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_12 && guest.other_field_12.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_13 && guest.other_field_13.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_14 && guest.other_field_14.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
				else if (guest.other_field_15 && guest.other_field_15.toString().toLowerCase().indexOf(word) >= 0) {
					searchedItems.push(guest);
				}
			}
		}

		return searchedItems;
	}

	getGuestDetails(eventId: string | number, guestId: string | number): Guest | null {
		let items: Guest[] = this.getEventGuests(`@event:${eventId}:guests`);

		if (items.length) {
			const guest = items.find((g) => g.id == guestId);

			if (guest) {
				return guest;
			}
		}
		return null;
	}

	setGuestDetails(eventId: string | number, guestId: string | number, guestDetails: Guest): null {
		let items: Guest[] = this.getEventGuests(`@event:${eventId}:guests`);

		if (items.length) {
			const newItems = items.map((g) => {
				if (g.id == guestId) {
					console.log('GUEST UPDATED IN CACHE', {
						old: g,
						new: guestDetails
					})
					return {
						...g,
						...guestDetails
					}
				}

				return g;
			});

			localStorage.setItem(`@event:${eventId}:guests`, JSON.stringify(newItems))
		}
		return null;
	}


	cacheApi: CacheApi = (method = 'POST', url, data) => {
		try {
			let apiRequests = [];
			let response = localStorage.getItem('@apiRequests');
			if (response) {
				apiRequests = JSON.parse(response);
			}

			apiRequests.push({
				method,
				url,
				data,
			})

			console.log('cacheApi:apiRequests', apiRequests)
			localStorage.setItem('@apiRequests', JSON.stringify(apiRequests));
		} catch (e) {
			console.log('error:cacheApi', e)
		}
	}
}

const cacheService = new CacheService();
export default cacheService;