import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Event } from 'types';
import { apiUpdateEvent } from 'api/events';
import dayjs from 'dayjs';
import { batch } from 'react-redux';
import { TimeZones } from 'constant';
import { unsetEditEvent } from 'redux/eventSlice';
import { InputGroup } from 'react-bootstrap';

type EventUpdateProps = {
	data: Event;
}

const EventUpdate = ({ data }: EventUpdateProps) => {
	const dispatch = useAppDispatch();
	const [eventData, setEventData] = useState<any>({
		name: data.name,
		start_data: data.start_data,
		time: data.time,
		timezone: data.timezone,
		location: data.location,
	});


	const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const files = e.target.files
		console.log("files:", files)
		if (files && files.length > 0) {
			setEventData({ ...eventData, logo: files[0] });
		}
	}


	const handleUpdateEvent = async () => {
		console.log({
			eventData,
		})

		if (eventData.name === '') {
			alert('Event name is required');
			return;
		}
		if (eventData.start_data === '') {
			alert('Event start date is required');
			return;
		}
		if (eventData.time === '') {
			alert('Event time is required');
			return;
		}
		if (eventData.timezone === '') {
			alert('Event timezone is required');
			return;
		}

		

		const response = await apiUpdateEvent(data.id, {
			...eventData,
		})

		if (response.success) {
			batch(() => {
				dispatch(unsetEditEvent())
			})
		} else {
			alert(response.message)
		}
	}

	return (
		<>
			<Modal show={true} className="createNewAccountModal" onHide={() => {
				dispatch(unsetEditEvent())
			}} aria-labelledby="contained-modal-title-vcenter" >
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							<span>Edit Event Details</span>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className={`step-form1`}>
							<Form.Group className="mb-3">
								<Form.Label>Event name</Form.Label>
								<Form.Control type="text" placeholder="Opening Ceremony" disabled value={eventData?.name} onChange={e => setEventData({ ...eventData, name: e.target.value })} />
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Event Start Date, Time & Time Zone</Form.Label>
								<InputGroup>
									<Form.Control type="date" min={(dayjs().format('YYYY-MM-DD'))} value={eventData?.start_data} onChange={e => setEventData({ ...eventData, start_data: e.target.value })} />
									
									<Form.Control type="time" value={eventData?.time} onChange={e => setEventData({ ...eventData, time: e.target.value })} />
									
									<Form.Select aria-label="Default select example" value={eventData?.timezone} onChange={e => setEventData({ ...eventData, timezone: e.target.value })}>
										{TimeZones.map(timezone => {
											return (
												<option key={timezone} value={timezone}>{timezone}</option>
											)
										})}
									</Form.Select>
								</InputGroup>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Location</Form.Label>
								<Form.Control type="text" placeholder="Enter the event venue" value={eventData?.location} onChange={e => setEventData({ ...eventData, location: e.target.value })} />
							</Form.Group>
						</div>

						<div className={`actionBtns`}>
							<Button variant="secondary" className={`btn-cancel `} onClick={() => {
								dispatch(unsetEditEvent())
							}}>
								<span className={`step1`}>Cancel</span>
							</Button>

							<Button variant="primary" className={`btn-steps `} onClick={handleUpdateEvent}>
								<span className={`step1 d-flex align-items-center justify-content-between`}>
									<span className="label">Update Information</span>
									<span className="arrow">
										<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</span>
								</span>
							</Button>
						</div>
					</Modal.Body>
				</>
			</Modal>
		</>
	)
}


export default EventUpdate;