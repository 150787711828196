import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import AdminRoutes from './AdminRoutes';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Button, Modal } from 'react-bootstrap';
import { AuthContext } from 'AuthContext';

const inactiveAlertTime = (60 * 1000) * 10; // 10 mins
const inactiveLogoutTime = ((60 * 1000) * 60) * 24; // 24 hours


const PrivateRoutes = (): JSX.Element => {
	const loggedIn = useAppSelector(state => state.auth.loggedIn);
	const userData = useAppSelector(state => state.auth.userData);
	const dispatch = useAppDispatch();
	const location = useLocation()
	const { logout } = useContext(AuthContext);
	const {width} = useWindowDimensions();

	const [idleAlert, setIdleAlert] = useState(false);
	let inactiveLogoutTimeout: any = null;
	useEffect(() => {
		const checkInactivityTimeout = setTimeout(() => {
			setIdleAlert(true);

			inactiveLogoutTimeout = setTimeout(() => {
				if (logout) {
					logout()
				}
			}, inactiveLogoutTime)
		}, inactiveAlertTime)

		return () => {
			if (checkInactivityTimeout) {
				clearTimeout(checkInactivityTimeout);
			}
			if (inactiveLogoutTimeout) {
				clearTimeout(inactiveLogoutTimeout);
			}
		}
	}, [location.pathname])


	if (!loggedIn || !userData) {
		return <Navigate to="/login" />
	}

	if (idleAlert) {
		return (
			<Modal 
				show={idleAlert} 
				className="inactivityModal" 
				// onHide={() => setIdleAlert(false)} 
				aria-labelledby="contained-modal-title-vcenter" 
				centered
			>
				<Modal.Header>
					<Modal.Title>
						<h5 className='m-0'>Are you still there?</h5>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>For security reasons, your connection times out after you've been inactive for a while.</p>
					<p>Click Continue to stay logged in.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={() => {
						if (logout) {
							logout()
						}
					}} style={{ width: 'auto', flexGrow: 0.3, padding: '0.7rem' }}>
						Logout
					</Button>
					<Button variant="dark" onClick={() => {setIdleAlert(false)}} style={{ width: 'auto', flexGrow: 0.7, padding: '0.7rem' }}>
						Continue
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}

	return <AdminRoutes />;
	
}

export default React.memo(PrivateRoutes);