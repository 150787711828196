import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'AuthContext';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { AdminUsers, Event, EventStatusType, PaginationMetaData } from 'types';
import { apiGetEvent } from 'api/events';
import dayjs from 'dayjs';
import { EventStatus } from 'constant';
import { setEditEvent } from 'redux/eventSlice';
import { setHeaderTitle } from 'redux/interfaceManger';
import { batch } from 'react-redux';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Form, Image, InputGroup } from 'react-bootstrap';
import Pagination from 'components/Pagination';
import { apiGetUsers } from 'api/users';
import UserUpdate from './components/UserUpdate';
import ThSortableCell from './components/ThSortableCell';

const Users = () => {
	const dispatch = useAppDispatch();
	const [users, setUsers] = useState<AdminUsers[]>([]);
	const [editUser, setEditUser] = useState<AdminUsers | null>(null);
	const [page, setPage] = useState<number>(1);
	const [limit, setLimit] = useState<number>(50);
	const [orderBy, setOrderBy] = useState<string>('created_at');
	const [orderType, setOrderType] = useState<string>('DESC');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [metaData, setMetaData] = useState<PaginationMetaData>({
		page: page,
		limit: limit,
		total: limit,
		total_pages: 1,
	})

	// const { height, width } = useWindowDimensions();


	useEffect(() => {
		batch(() => {
			dispatch(setHeaderTitle('Users'))
		})
	}, [])

	useEffect(() => {

		(async () => {
			const response = await apiGetUsers({
				page,
				limit,
				orderBy,
				orderType,
				q: searchQuery
			})

			if (response.success) {
				setUsers(response.data.items)
				setMetaData(response.data.meta_data)
			}
		})()

	}, [page, orderBy, orderType, searchQuery, editUser])




	return (
		<>
			<div className='dashboardPage mb-5'>
				<div className="cp_table cp_wdth card eventcard">
					<div className='p-2'>
						<Row>
							<Col>
								<h5 className='mb-2 mt-2'>All Users</h5>
							</Col>
							<Col md={3}>
								<Form.Control
									placeholder="Search name or email"
									aria-label="search"
									aria-describedby="basic-addon1"
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
							</Col>
						</Row>
					</div>
					<div className="card-body" style={{ padding: 0 }}>
						<table className="table table-borderless">
							<thead>
								<tr>
									<th style={{ width: '5%' }}><ThSortableCell title='Status' colName='status' orderBy={orderBy} orderType={orderType} setOrderBy={setOrderBy} setOrderType={setOrderType} /></th>
									<th style={{ width: '5%' }}></th>
									<th style={{ width: '8%' }}><ThSortableCell title='User ID' colName='id' orderBy={orderBy} orderType={orderType} setOrderBy={setOrderBy} setOrderType={setOrderType} /></th>
									<th style={{ width: '14%' }}><ThSortableCell title='Name' colName='name' orderBy={orderBy} orderType={orderType} setOrderBy={setOrderBy} setOrderType={setOrderType} /></th>
									<th style={{ width: '20%' }}><ThSortableCell title='Email' colName='email' orderBy={orderBy} orderType={orderType} setOrderBy={setOrderBy} setOrderType={setOrderType} /></th>
									<th style={{ width: '20%' }}><ThSortableCell title='Total Active events' colName='total_active_events' orderBy={orderBy} orderType={orderType} setOrderBy={setOrderBy} setOrderType={setOrderType} /> <div className='muted small'>(including the Archived ones which were paid for)</div></th>
									<th style={{ width: '8%' }}><ThSortableCell title='Event Credits' colName='event_credits' orderBy={orderBy} orderType={orderType} setOrderBy={setOrderBy} setOrderType={setOrderType} /></th>
									<th style={{ width: '10%' }}><ThSortableCell title='Last login' colName='last_logged_in' orderBy={orderBy} orderType={orderType} setOrderBy={setOrderBy} setOrderType={setOrderType} /></th>
									<th style={{ width: '10%' }}><ThSortableCell title='Creation date' colName='created_at' orderBy={orderBy} orderType={orderType} setOrderBy={setOrderBy} setOrderType={setOrderType} /></th>
								</tr>
							</thead>
							<tbody>
								{users.map((user, index) => {
									return (
										<tr key={index} >
											<td style={{ width: '5%' }}>
												<div
													className={`status-badge ${(user.status === 'paying') ? 'active' : ''} ${(user.status === 'testing') ? 'testing' : ''} ${(user.status === 'deleted' || user.status === 'blocked') ? 'archived' : ''} `}
													style={{ margin: '0' }}
												>
													<span className="dot"></span>
													<span className="label">
														{user.status}
													</span>
												</div>
											</td>
											<td style={{ width: '5%' }}>
												<div style={{ width: 80, height: 80, margin: '0 auto' }} >
													<Image src={(user.image) ? user.image : require('assets/images/placeholder-event.jpg')} alt="user logo" fluid roundedCircle  style={{ height: '100%' }} />
												</div>
											</td>
											<td style={{ width: '8%', lineHeight: '22px'  }}>
												{user.id}
											</td>
											<td style={{ width: '14%', lineHeight: '22px'  }}>
												{user.name}
											</td>
											<td style={{ width: '20%', textDecoration: 'underline', }}>
												<div style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}>
													<div>
														<Button 
															type='button'
															variant='link'
															className='text-dark'
															onClick={() => setEditUser(user)}
														>{user.email}</Button>
													</div>
													<div>
														<Button type='button' variant='dark' size='sm' onClick={() => setEditUser(user)} >
															<i>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M13.6 4L16 6.4M4 16L4.76584 13.1919C4.81581 13.0087 4.84079 12.9171 4.87914 12.8317C4.91319 12.7558 4.95503 12.6837 5.00399 12.6166C5.05913 12.5409 5.12627 12.4737 5.26054 12.3395L11.4606 6.13941C11.5794 6.02061 11.6388 5.9612 11.7073 5.93895C11.7675 5.91937 11.8325 5.91937 11.8927 5.93895C11.9612 5.9612 12.0206 6.02061 12.1394 6.13941L13.8606 7.86059C13.9794 7.97939 14.0388 8.0388 14.0611 8.1073C14.0806 8.16755 14.0806 8.23245 14.0611 8.29271C14.0388 8.3612 13.9794 8.42061 13.8606 8.53941L7.66054 14.7395C7.52627 14.8737 7.45913 14.9409 7.38345 14.996C7.31625 15.045 7.24415 15.0868 7.16831 15.1209C7.08289 15.1592 6.99129 15.1842 6.80808 15.2342L4 16Z" stroke="#FFF" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
																</svg>
															</i>
														</Button>
													</div>
												</div>
											</td>
											<td style={{ width: '20%' }} className='text-center'>
												{user.total_active_events}
											</td>
											<td style={{ width: '8%' }} className='text-center'>
												{(user.event_credits) ? user.event_credits : 0}
											</td>
											<td style={{ width: '10%', fontWeight: 'normal', fontSize: 12, lineHeight: '18px' }}>
												<div>{(user.last_logged_in) ? dayjs(user.last_logged_in).format('MMM DD, YYYY') : '-'}</div>
											</td>
											<td style={{ width: '10%', fontWeight: 'normal', fontSize: 12, lineHeight: '18px' }}>
												{dayjs(user.created_at).format('MMM DD, YYYY')}
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>

						{metaData.total_pages > 1 ? (
						<Row>
							<Col>
								<div className='p-3'>
									Page {metaData.page} / {metaData.total_pages}
								</div>
							</Col>
							<Col className='text-end'>
								<div className='p-3'>
									<Pagination metaData={metaData} handlePage={setPage} />
								</div>
							</Col>
						</Row>
						) : null}
					</div>
				</div>

			</div>

			{(editUser !== null) ? (
				<UserUpdate data={editUser} onCancel={() => { setEditUser(null) }} />
			) : null}
		</>
	);
};

export default Users;