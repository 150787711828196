import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventCardStyle } from 'constant';
import { ReactElement } from 'react';
import { EventCardStyleType, InterfaceManager } from 'types';


const initialState: InterfaceManager = {
	isOnline: true,
	headerTitle: '',
	lastPage: '',
	footerMessage: '',
}

export const interfaceMangerSlice = createSlice({
	name: 'interfaceManger',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setOnline(state: InterfaceManager, action: PayloadAction<boolean>) {
			state.isOnline = action.payload;
		},
		setHeaderTitle(state: InterfaceManager, action: PayloadAction<string>) {
			state.headerTitle = action.payload;
		},
		setLastPage(state: InterfaceManager, action: PayloadAction<string>) {
			state.lastPage = action.payload;
		},
		setFooterMessage(state: InterfaceManager, action: PayloadAction<string | ReactElement>) {
			state.footerMessage = action.payload;
		},
	},
});


export const {
	setOnline,
	setHeaderTitle,
	setLastPage,
	setFooterMessage,
} = interfaceMangerSlice.actions;

export default interfaceMangerSlice.reducer;
