import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventStatus } from 'constant';
import { Event, EventStatusType } from 'types';


type EventSliceProps = {
	editEvent: Event | null
}

const initialState: EventSliceProps = {
	editEvent: null,
}

export const eventSlice = createSlice({
	name: 'events',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setEditEvent(state: EventSliceProps, action: PayloadAction<Event>) {
			state.editEvent = action.payload;
		},
		unsetEditEvent(state: EventSliceProps) {
			state.editEvent = null;
		}
	},
});


export const {
	setEditEvent,
	unsetEditEvent,
} = eventSlice.actions;

export default eventSlice.reducer;
