import { Routes, Route } from 'react-router-dom';

import AuthLayout from 'layout/AuthLayout';

import Login from 'views/Auth/Login';
import NotFound from 'views/NotFound';

const AuthRoutes = (): JSX.Element => {

	return (
		<Routes>
			<Route path="/" element={<AuthLayout />}>
				<Route element={<Login />} path="/" />
				<Route element={<Login />} path="/login" />
				<Route element={<Login />} path="/logout" />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	)
}

export default AuthRoutes;