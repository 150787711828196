import { apiLoginVerify } from 'api/auth';
import React, { useEffect, useState } from 'react';
import { Alert, Button, FormControlProps, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { batch } from 'react-redux';
import { setLoggedIn, setUserData } from 'redux/authSlice';
import { useAppDispatch } from 'redux/hooks';

interface LoginVerificationCodeProps {
	email: string;
	reset: (status: boolean) => void;
}

const LoginVerificationCode: React.FC<LoginVerificationCodeProps> = ({
	email,
	reset,
}) => {
	const dispatch = useAppDispatch();
	const [errorMsg, setErrorMsg] = useState('');
	const [code, setCode] = useState<string>('')

	const change = (e: any) => {
		setCode(e.target.value)
	}

	const submit = async (e: any) => {
		e.preventDefault();

		if (!email || !code || code.length < 6) {
			return;
		}

		setErrorMsg('');

		const response = await apiLoginVerify({ email, code });

		if (response.success) {
			batch(() => {
				dispatch(setLoggedIn(true));
				dispatch(
					setUserData({
						...response.data,
						roleName: response.data.role.name,
					}),
				);
			});
		} else {
			setErrorMsg(response.message)
		}
	}

	return (
		<>
			<Form onSubmit={submit}>
				<Form.Group className="mb-3 cp_frm_inp" controlId="exampleForm.ControlInput1">
					<Form.Label>Verification Code</Form.Label>
					<p className='small text-muted'>To continue, please enter the 6-digit verification code sent to your email.</p>
					{errorMsg && (<Alert variant='danger'>{errorMsg}</Alert>)}
					<Form.Control 
						type="text" 
						placeholder="" 
						value={code} 
						onChange={change}
						onKeyDown={(e) => {
							if (e.key === 'Backspace' || e.key === 'Delete') return;
							if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') return;
							if (!/\d/.test(e.key)) {
								e.preventDefault();
							}
						}}
						maxLength={6}
						style={{
							letterSpacing: 10,
							textAlign: 'center',
							fontSize: '1.5rem'
						}}
					/>
				</Form.Group>
				<div className="cp_frm_btn mt-4">
					<Button type="submit" variant='dark'>
						<span>Verify</span>
						<i>
							<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 5H9M9 5L5 1M9 5L5 9" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</i>
					</Button>
				</div>

				<div className="text-center">
					<Button type='button' variant='link' className='mt-4 text-dark' onClick={() => reset(false)}>Return to Login</Button>
				</div>
			</Form>

		</>
	)
}

export default LoginVerificationCode;