import React, { useEffect, useState } from 'react';

type CellProps = {
	title: string
	colName: string
	orderBy: string
	orderType: string
	setOrderBy: (t: string) => void
	setOrderType: (t: string) => void
}

const ThSortableCell = ({
	title,
	colName,
	orderBy,
	orderType,
	setOrderBy,
	setOrderType
}: CellProps) => {

	return (
		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			cursor: 'pointer',
		}}
		onClick={() => {
			console.log({colName, orderBy, orderType})
			if (orderBy === colName) {
				setOrderType(orderType === 'ASC' ? 'DESC' : 'ASC')
			} else {
				setOrderBy(colName)
				setOrderType('DESC')
			}
		}}
		>
			<div>{title}</div>
			{(orderBy === colName) ? (
				<>
					{(orderType === 'ASC') ? (
						<i>
							<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.5 9L5.5 1M5.5 1L1.5 5M5.5 1L9.5 5" stroke="#333333" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
							</svg>
						</i>
					) : (
						<i>
							<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: 'rotate(180deg)'}}>
								<path d="M5.5 9L5.5 1M5.5 1L1.5 5M5.5 1L9.5 5" stroke="#333333" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
							</svg>
						</i>
					)}
				</>
			) : null}
		</div>
	)
}

export default ThSortableCell;