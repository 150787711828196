import { ResponseObject, Event, EventDataField, EventStatusType, PaginationMetaData, AdminUsers } from "types";
import apiService from "services/ApiService";
import cacheService from "services/CacheService";

export type ApiUsersResponse = {
	items: AdminUsers[]
	meta_data: PaginationMetaData
}

type QueryParams = {
	page: number,
	limit: number,
	orderBy: string,
	orderType: string,
	q?: string
}

export const apiGetUsers = async (
	{
		page = 1,
		limit = 10,
		orderBy = 'id',
		orderType = 'DESC',
		q
	}: QueryParams
): Promise<ResponseObject<ApiUsersResponse>> => {

	const response = await apiService.get('/users', {
		page,
		limit,
		orderBy,
		orderType,
		q
	});

	return response;
}


export const apiUpdateUser = async (userId: string | number, data: any): Promise<ResponseObject<null>> => {
	return await apiService.post(`/update-user/${userId}`, data);
}