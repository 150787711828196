import React, { useEffect, useState } from 'react';
import { PaginationMetaData } from "types"
import { Button, ButtonGroup } from 'react-bootstrap';

interface PaginationProps {
	metaData: PaginationMetaData
	handlePage: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
	metaData,
	handlePage,
}) => {

	return (
		<>
			<ButtonGroup aria-label="Pagination">
				<Button
					type='button'
					variant='dark'
					active={metaData.page === 1}
					onClick={() => handlePage(1)}
				>First</Button>

				{metaData.page > 3 && (
					<Button type="button" variant="dark">
						...
					</Button>
				)}
				{Array.from({ length: 5 }, (_, index) => {
					// Determine the starting page
					let startPage = Math.max(
						1,
						Math.min(metaData.page - 2, metaData.total_pages - 4)
					);

					// Calculate the page number for each button in the group
					const pageNumber = startPage + index;

					// Ensure that the page number is within the valid range
					if (pageNumber > metaData.total_pages) return null;

					return (
						<Button
							key={pageNumber}
							type='button'
							variant='dark'
							active={metaData.page === pageNumber}
							onClick={() => handlePage(pageNumber)}
						>
							{pageNumber}
						</Button>
					);
				})}
				{metaData.page < metaData.total_pages - 2 && (
					<Button type="button" variant="dark">
						...
					</Button>
				)}

				<Button
					type='button'
					variant='dark'
					active={metaData.page === metaData.total_pages}
					onClick={() => handlePage(metaData.total_pages)}
				>Last</Button>
			</ButtonGroup>
		</>
	)
}

export default Pagination;