import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const NotFound = () => {
	const loggedIn = useAppSelector(state => state.auth.loggedIn);

	if (loggedIn) {
		// if user login and route not found then redirect to home page
		return <Navigate to="/" />
	}

	return <Navigate to="/login" />

	// return <h1>404 Not Found</h1>;
};

export default NotFound;