import React, {useEffect, useState} from 'react';
import { Button, FormControlProps, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

interface PasswordFieldProps {
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	placeholder?: string;
	style?: React.CSSProperties;
	enableValidation?: boolean
	matchWithValue?: string
}

const PasswordField: React.FC<PasswordFieldProps> = ({ 
	value, 
	onChange, 
	placeholder,
	style,
	enableValidation = false,
	matchWithValue,
}) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [validation, setValidation] = useState<{
		hasLowerCase: boolean
		hasUpperCase: boolean
		hasNumber: boolean
		hasSpecial: boolean
		hasMinLength: boolean
	}>({
		hasLowerCase: false,
		hasUpperCase: false,
		hasNumber: false,
		hasSpecial: false,
		hasMinLength: false,
	})

	useEffect(() => {
		if (enableValidation) {
			validate(value)
		}
	}, [value])

	const toggleVisibility = () => {
		setIsVisible(prev => !prev);
	};

	const validate = (val: string) => {
		if (!val || val === '') {
			setValidation({
				hasLowerCase: false,
				hasUpperCase: false,
				hasNumber: false,
				hasSpecial: false,
				hasMinLength: false,
			})
			return;
		}

		const hasLowerCase = /[a-z]/.test(val);
		const hasUpperCase = /[A-Z]/.test(val);
		const hasNumber = /\d/.test(val);
		const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(val);
		const hasMinLength = val.length >= 8;

		// Update the password complexity status based on the checks
		setValidation({
			hasLowerCase,
			hasUpperCase,
			hasNumber,
			hasSpecial,
			hasMinLength,
		})
	}


	return (
		<>
		<InputGroup>
			<Form.Control 
				type={isVisible ? "text" : "password"}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				style={style}
				isValid={enableValidation ? (
					validation.hasLowerCase && validation.hasUpperCase && validation.hasNumber && validation.hasSpecial && validation.hasMinLength
				) : (
					matchWithValue !== undefined && matchWithValue !== '' && matchWithValue === value
				)}
			/>
			<Button 
				variant="white" 
				type="button" 
				onClick={toggleVisibility} 
			>
			{isVisible ? (
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{verticalAlign: 'text-bottom'}}>
					<path fillRule="evenodd" clipRule="evenodd" d="M22 12.0002C20.2531 15.5764 15.8775 19 11.9998 19C8.12201 19 3.74646 15.5764 2 11.9998" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M22 12.0002C20.2531 8.42398 15.8782 5 12.0005 5C8.1227 5 3.74646 8.42314 2 11.9998" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			) : (
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{verticalAlign: 'text-bottom'}}>
					<path d="M4 4L9.87868 9.87868M20 20L14.1213 14.1213M9.87868 9.87868C9.33579 10.4216 9 11.1716 9 12C9 13.6569 10.3431 15 12 15C12.8284 15 13.5784 14.6642 14.1213 14.1213M9.87868 9.87868L14.1213 14.1213M6.76821 6.76821C4.72843 8.09899 2.96378 10.026 2 11.9998C3.74646 15.5764 8.12201 19 11.9998 19C13.7376 19 15.5753 18.3124 17.2317 17.2317M9.76138 5.34717C10.5114 5.12316 11.2649 5 12.0005 5C15.8782 5 20.2531 8.42398 22 12.0002C21.448 13.1302 20.6336 14.2449 19.6554 15.2412" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			)}
			</Button>
		</InputGroup>

		{(enableValidation) && (
			<div className='mt-2 password-complexity-section'>
				<div className={`password-status ${validation.hasLowerCase ? 'is-valid' : ''} `}>One lowercase character</div>
				<div className={`password-status ${validation.hasSpecial ? 'is-valid' : ''} `}>One special character</div>
				<div className={`password-status ${validation.hasUpperCase ? 'is-valid' : ''} `}>One uppercase character</div>
				<div className={`password-status ${validation.hasMinLength ? 'is-valid' : ''} `}>8 characters minimum</div>
				<div className={`password-status ${validation.hasNumber ? 'is-valid' : ''} `}>One number</div>
			</div>
		)}
		</>
	)
}

export default PasswordField;